import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private httpClient: HttpClient) {
  }

  public getBlogList() {
    return this.httpClient.post(`/panel/api/u/v1/blogs/list`, {records: 'all'});
  }
  public getBlogListByCategory(categoryId) {
    return this.httpClient.post(`/panel/api/u/v1/blogs/list`, {records: 'all', categoryId});
  }

  public getBlogCategoryList() {
    return this.httpClient.post(`/panel/api/u/v1/blogs/categories`, {records: 'all'});
  }


  public getBlogData(blogId) {
    return this.httpClient.get(`/panel/api/u/v1/blogs/view/${blogId}`);
  }
}
