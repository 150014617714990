import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebpagesService {

  constructor(private httpClient: HttpClient) {
  }
  public getWebpageData(pageId: string) {
    return this.httpClient.get(`/panel/api/u/v1/webpages/details/${pageId}`);
  }
}
