import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardComponent } from './product-card/product-card.component';
import { WebpageComponent } from './webpage/webpage.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ProductCardComponent, WebpageComponent],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    ProductCardComponent,
    WebpageComponent
  ]
})
export class SharedModule { }
