import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-webpage',
  templateUrl: './webpage.component.html',
  styleUrls: ['./webpage.component.css']
})
export class WebpageComponent implements OnInit {
 @Input()
 public heading = '';
 @Input()
 public content = '';
 
  constructor() { }

  ngOnInit() {
  }

}
