import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageService } from '../../services/local-storage.service';
import { CartsService } from '../../services/carts.service';
import { ToastrService } from 'ngx-toastr';
declare var jQuery: any;

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit {

  @Input()
  item = {};
  constructor(private cartService: CartsService, private toastr: ToastrService) { }

  ngOnInit() {
  }

  public addToCart(items, qty) {
    this.cartService.addToCart(items, qty);
    this.toastr.success('', 'Items added to cart successfully');
  }

}
