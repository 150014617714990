import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private httpClient: HttpClient) { }


  public getAllProducts() {
    return this.httpClient.post(`/panel/api/u/v1/products/list`, {records: 'all'});
  }

  public getProductDetails(id) {
    return this.httpClient.get(`/panel/api/u/v1/products/view/${id}`);
  }


  public getProductsBySearchTerm(searchTerm) {
    return this.httpClient.post(`/panel/api/u/v1/products/list`, { records: 'all', searchTerm});
  }


  public getProductsByCategories(categories) {
    return this.httpClient.post(`/panel/api/u/v1/products/list`, { records: 'all', categories});
  }
}
